import React, { useEffect, useRef, useState } from "react";
import Router from "next/router";

import { absoluteOffset } from "@kikoff/client-utils/src/dom";
import { combineClasses } from "@kikoff/utils/src/string";

import LandingFooter from "@component/layout/footer";
import Join from "@component/layout/join";
import LandingNav from "@component/layout/nav";

import styles from "./landing.module.scss";

export const LandingLayout: React.FC = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [top, setTop] = useState(true);
  const [hideNav, setHideNav] = useState(false);
  const [hideJoin, setHideJoin] = useState(true);

  useEffect(() => {
    const contentContainer = contentRef.current;

    const joinEl = ref.current.querySelector("[data-layout-sticky]");
    if (!joinEl) {
      return;
    }

    const $join = joinEl as HTMLElement;
    const joinHeight = $join.scrollHeight;

    let bottomOfMainCta: number;
    let scrollData = {
      diff: 0,
      last: 0,
    };

    function scroll() {
      const { scrollTop } = document.body;

      setTop(scrollTop < 1);

      const scrollBottom = scrollTop + document.body.clientHeight;
      setHideJoin(scrollTop < bottomOfMainCta);

      // This is done imperatively to prevent cta from jumping
      $join.style.position =
        scrollBottom >=
        contentContainer.scrollHeight +
          // Layout padding-top
          72 +
          joinHeight
          ? "static"
          : "fixed";

      const diff = scrollTop - scrollData.last;
      scrollData = {
        diff:
          Math.sign(diff) === Math.sign(scrollData.diff)
            ? scrollData.diff + diff
            : diff,
        last: scrollTop,
      };
      if (Math.abs(scrollData.diff) > 50) {
        setHideNav(scrollData.diff > 0);
      }
    }

    function resize() {
      const mainCtaEl = contentContainer.querySelector(
        "[data-sticky-footer-anchor]"
      );
      const offset = absoluteOffset(mainCtaEl as HTMLElement);
      bottomOfMainCta = offset.top + offset.height;
      scroll();
    }

    resize();
    // Refresh after layout reflow
    requestAnimationFrame(resize);

    function routeChange() {
      scroll();
      document.body.scrollTo({ top: 0 });
    }

    Router.events.on("routeChangeComplete", routeChange);
    document.body.addEventListener("scroll", scroll);
    window.addEventListener("resize", resize);

    return () => {
      Router.events.off("routeChangeComplete", routeChange);
      document.body.removeEventListener("scroll", scroll);
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <div className={styles["landing-layout"]} ref={ref}>
      <LandingNav
        className={combineClasses(
          styles.nav,
          top && styles.top,
          hideNav && styles.hidden
        )}
        hidden={hideNav}
      />
      <div className={styles.content} ref={contentRef}>
        {children}
      </div>
      <Join
        className={combineClasses(styles.join, hideJoin && styles.hidden)}
      />
      <LandingFooter />
    </div>
  );
};

export default function getLandingLayout(page, props) {
  return <LandingLayout {...props}>{page}</LandingLayout>;
}
